import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import {Link} from "gatsby"

import RichText from "../components/rich-text"
import Image from "../components/image"

const SidebarAboutMe = () => {
  const data = useStaticQuery(graphql`
    query aboutSidebar {
      about: contentfulSimpleLife(slug: {eq: "chi-siamo"}) {
        slug
        title
        shortDescription {
          json
        }
        cover {
          file {
            url
            fileName
            contentType
            details {
              image {
                height
                width
              }
              size
            }
          }
          fluid {
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className="sidebar-widget">
      <h2 className="widget-title">CHI SIAMO</h2>
      <Link  to={'/'+data.about.slug} >
        <Image fluid={data.about.cover.fluid} file={data.about.cover.file} alt={data.about.coverAltText || 'alt text'} classStyle="widget-image" />
      </Link>
      <div className="widget-body">
        <RichText text={data.about.shortDescription.json}  />
      </div>
    </div>
  )};

export default SidebarAboutMe;
