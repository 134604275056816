import React from 'react'
import propTypes from 'prop-types'
import Img from 'gatsby-image'
 
// Render inline SVG with fallback non-svg images
const Image = ({ fluid, file, alt, classStyle }) => {
  if (file.contentType === 'image/svg+xml') {
    /*
    if (svgContent) {
      // Inlined SVGs
      return <div dangerouslySetInnerHTML={{ __html: svgContent }} />
    }
    */
    // SVGs that can/should not be inlined
    return <img src={file.url} alt={alt} className={classStyle} />
  }
 
  // Non SVG images
  return <Img fluid={fluid} alt={alt} className={classStyle} />
}
 
Image.propTypes = {
  svgContent: propTypes.string,
  fluid: propTypes.object,
  file: propTypes.object.isRequired,
  alt: propTypes.string.isRequired,
  classStyle: propTypes.string,
}

export default Image
