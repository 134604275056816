import React from "react";
import {Link} from "gatsby"

import RichText from "./rich-text"

const formatDate = (strDate) => {
  const date = new Date(strDate)
  return date.toString().substr(4,6)+','+date.toString().substr(10,5)
}

const Article = ({node}) => (
  <article className="article-recent">
  <div className="article-recent-main">
    <h2 className="article-title">{node.title} </h2>
    <div className="article-body">
      <RichText text={node.shortDescription ? node.shortDescription.json:''} />
    </div>
    <Link  to={'/'+node.slug} className="article-read-more " >
            CONTINUA A LEGGERE
    </Link>
  </div>
  <div className="article-recent-secondary">
    <img
      src={node.cover? node.cover.fluid.src:''}
      alt="no description available"
      className="article-image"
    />
    <p className="article-info">{formatDate(node.date)} </p>
  </div>
</article>


)

export default Article